import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import CutContainer from "../components/cutContainer";
import Footer from "../components/footer";
import Header from "../components/header";
import Layout from "../components/layout";
import Translate from "../components/translate";
import GetStarted from "../components/getStarted";
import { purpleGradient } from "../services/constants";
import globalStyles from "../services/globalStyles.module.scss";
import styles from "./about.module.scss";

class AboutUsPage extends React.Component {
  render() {
    const { xps, simple, service, texts: { _ }, staffContent } = this.props.data;
    const languageCode = this.props.pageContext && this.props.pageContext.lang ? this.props.pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} mobileHeaderFullYOffset={40} fixed whiteFont languageCode={languageCode} />} translations={_} languageCode={languageCode}>

        <CutContainer
          style={{
            background: purpleGradient,
            position: "relative",
          }}
          sizeOfCut={2}
          styleClass={styles.cutContainer}
          left
        >
          <h1 className={styles.header}>
            {" "}{_.about_us.header}{" "}
          </h1>
          <div className={globalStyles.contentContainer140}>
            <div className={styles.container}>
              <iframe src="https://player.vimeo.com/video/334479471?autoplay=1&loop=1&autopause=0"
                frameBorder="0" allowFullScreen allow="autoplay" className={styles.video}></iframe>
            </div>
          </div>
        </CutContainer>

        <div className={styles.content}>
          <div className={styles.contentSection}>
            <div>
              <h1>{_.about_us.section_1_heading}</h1>
              <p>{_.about_us.section_1_paragraph_1}</p>
              <p>{_.about_us.section_1_paragraph_2}</p>
              <p>{_.about_us.section_1_paragraph_3}</p>
              <p>{_.about_us.section_1_paragraph_4}</p>
            </div>
            <Img fluid={xps.childImageSharp.fixed} className={styles.contentSectionImageRight} />
          </div>

          <div className={styles.contentSection + ' ' + styles.contentSectionLeft}>
            <Img fluid={simple.childImageSharp.fixed} className={styles.contentSectionImageLeft} />
            <div>
              <h2 style={{ marginTop: 60 }}>{_.about_us.section_2_heading}</h2>
              <p>{_.about_us.section_2_paragraph_1}</p>
              <p>{_.about_us.section_2_paragraph_2}</p>
            </div>
          </div>

          <div className={styles.contentSection}>
            <div>
              <h2>{_.about_us.section_3_heading}</h2>
              <p>
                <Translate template={_.about_us.section_3_paragraph_1}>
                  <a href="mailto:info@sidelinesports.com"></a>
                  <a href="mailto:support@sidelinesports.com"></a>
                </Translate>
              </p>
              {/* <p>
                <Translate template={_.about_us.section_3_paragraph_2}>
                  <a href="mailto:thorkelsson@sidelinesports.com"></a>
                </Translate>
              </p> */}
              <p>{_.about_us.section_3_paragraph_3}</p>
            </div>
            <Img fluid={service.childImageSharp.fixed} style={{ marginTop: -30 }} className={styles.contentSectionImageRight} />
          </div>
        </div>

        <div style={{ paddingTop: 50 }} className={styles.teamContainer}>
          <h1
            style={{ marginBottom: 110, marginTop: 64 }}
            className={styles.teamTitle}>{_.about_us.management}</h1>
          <div className={styles.team}>
            {staffContent.edges.map((edge, i) => 
              <div key={i} className={styles.teamMateContainer}>
                <div className={styles.managementAvatarContainer}>
                  <img src={edge.node.photo} className={styles.avatar} />
                </div>
                <a className={styles.href} href={`mailto:${edge.node.email}`}>
                  <h1 className={styles.teamMateName}>{edge.node.name}</h1>
                </a>
                <h2 className={styles.teamMateRole}>{edge.node.title}</h2>
              </div>
            )}
          </div>
        </div>
        <GetStarted languageCode={languageCode} translations={_}/>
        <Header num={2} translations={_} whiteFont className={globalStyles.hideOnMobile} seeThrough headerFullYOffset={500} languageCode={languageCode} />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default AboutUsPage

export const query = graphql`
  query AboutUs($langFile: String) {
    site{
      siteMetadata{
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        enter_your_email
        start_free_trial
        time_to_get_started
        countries {
          australia
          austria
          gcc
          norway
          sweden
          czech_republic
          new_zealand
          luxembourg
          germany
          iceland
          faroe_islands
          latvia
          portugal
          hungary
          finland
          russia
          spain
          denmark
        }
        about_us {
          header

          section_1_heading
          section_1_paragraph_1
          section_1_paragraph_2
          section_1_paragraph_3
          section_1_paragraph_4

          section_2_heading
          section_2_paragraph_1
          section_2_paragraph_2

          section_3_heading
          section_3_paragraph_1
          section_3_paragraph_2
          section_3_paragraph_3

          leadership
          management
          roles {
            ceo
            cto
            coo
            director_of_video_analysis
            director_of_business_development
            director_of_customer_support
            director_of_pr_and_marketing
            director_of_sales
            sidelinesports_australia
          }
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    xps: file(
      relativePath: { eq: "aboutUs/xps.webp" }
    ) {
      childImageSharp {
        fixed(width: 640, height: 635) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    simple: file(
      relativePath: { eq: "aboutUs/simple.webp" }
    ) {
      childImageSharp {
        fixed(width: 640, height: 635) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    service: file(
      relativePath: { eq: "aboutUs/service.webp" }
    ) {
      childImageSharp {
        fixed(width: 640, height: 635) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    staffContent: allWordpressStaff {
      edges {
        node {
          name: wpcf_name
          title: wpcf_title
          photo: wpcf_photo
          email: wpcf_email
        }
      }
    }
  }
`
